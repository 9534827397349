<template>
  <div class="login-container" :style="{backgroundImage: 'url('+ backgroundImage +')'}">
    <div class="system-title">
      长江上游朝涪河段航道整治工程智慧工地
    </div>
    <div class="login">
      <div class="title">
        用户登录Login
      </div>
      <div class="e-tab">
        <div class="e-title">
          <div class="item" :class="{ selected : isUserLogin }" @click="isUserLogin = true">
            账号登录
          </div>
          <!-- <div class="item" :class="{ selected : !isUserLogin }" @click="isUserLogin = false">
            手机号登录
          </div> -->
        </div>
        <div class="e-content">
          <div class="item" :class="{ selected : isUserLogin }">
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
              <el-form-item prop="username">
                <el-input
                  ref="username"
                  v-model="loginForm.username"
                  placeholder="请输入账号"
                  name="username"
                  type="text"
                  tabindex="1"
                  auto-complete="on"
                />
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  placeholder="请输入密码"
                  name="password"
                  tabindex="2"
                  auto-complete="on"
                  @keyup.enter.native="handleLogin"
                />
              </el-form-item>
              <el-form-item>
								<el-checkbox v-model="isRemember">记住密码</el-checkbox>
							</el-form-item>

              <el-button :loading="loading" class="login-btn" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>
              <!-- <input type="text" v-model="loginForm.username" placeholder="请输入账号" />
              <input type="password" v-model="loginForm.password" placeholder="请输入密码" />
              <button class="login-btn" @click="handleLogin">登录</button> -->
            </el-form>
          </div>
          <div class="item" :class="{ selected : !isUserLogin }">
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
              <el-form-item prop="phone">
                <el-input
                  ref="phone"
                  v-model="loginForm.phone"
                  placeholder="请输入手机号"
                  name="phone"
                  type="text"
                  tabindex="1"
                  auto-complete="on"
                />
              </el-form-item>
              <el-form-item prop="code" style="width: 158px;display: inline-block;">
                <el-input
                  ref="password"
                  v-model="loginForm.code"
                  placeholder="请输入验证码"
                  name="code"
                  tabindex="2"
                  auto-complete="on"
                  @keyup.enter.native="phoneLogin"
                />
              </el-form-item>
              <!-- <el-button type="primary" plain>获取验证码</el-button> -->
              <button class="get-code" :disabled="sendCodeDisabled" type="button" @click="sendCode">{{ showCodeText }}</button>
              <el-button :loading="loading" class="login-btn" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="phoneLogin">登录</el-button>
            </el-form>
          </div>
        </div>
        <!-- <div class="forgot-pwd-reg">
          <a href="${ctx }/forgetPassword">忘记密码</a>
          |
          <a href="${ctx }/register">注册</a>
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <div class="text">
        欢迎来到 长江上游朝涪河段航道整治工程智慧工地 网站。
      </div>
      <div class="text">
        CopyRight@ CHANGJIANG CHONGQING WATERWAY Exchange All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { sendCode } from '@/api/user'
import Settings from '@/settings'

export default {
  name: 'Login',
  data() {
    return {
      title: '',
      backgroundImage: require('../../assets/background.png'),
      isUserLogin: true,
      loginForm: {
        username: '',
        password: '',
        phone:'',
        code:''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入账号' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      showCodeText: '获取验证码',
      sendCodeDisabled : false,
      isRemember:false
    }
  },
  mounted() {
    Settings.title.then(title=> this.title = title)
    this.checkBrowser()
    var rememberTime = localStorage.rememberTime
		if(new Date().getTime() < rememberTime + 1000 * 60 * 60 * 24 * 15) {
				this.loginForm.username = localStorage.username;
				this.loginForm.password = localStorage.password;
			}
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    checkBrowser() {
			var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
			console.log(window.location.href);
			if(userAgent.indexOf("Chrome") == -1) { //判断是否Chrome浏览器
				this.$confirm('推荐使用谷歌浏览器，如需继续使用现有非谷歌浏览器，需要点击确认按钮', '提示', {
					confirmButtonText: '下载',
					cancelButtonText: '确认',
					type: 'warning'
				}).then(() => {
					window.open('https://chaofu-oss.oss-cn-beijing.aliyuncs.com/userfiles/2021-10-09/694007a6e3884d40b5e9058cd27a4d44/ChromeSetup.exe')
				}).catch(() => {
				});
			}
		},
    sendCode(){
      sendCode(this.loginForm.phone).then((response)=>{
        const { data } = response
        if(data){
          this.$message({
            message: '发送成功！',
            type: 'success'
          });
        }else{
          this.$message.error('发送失败！');
        }
      })
      let count = 60;
      this.sendCodeDisabled = true;
      this.showCodeText = `${count}秒后重新获取`;
      let timer = setInterval(()=>{
        if(--count == 0){
          this.showCodeText = '获取验证码';
          this.sendCodeDisabled = false;
          clearInterval(timer);
        }else{
          this.showCodeText = `${count}秒后重新获取`;
        }
      },1000)
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          if(this.isRemember) {
							localStorage.setItem("username", this.loginForm.username); //存储
							localStorage.setItem("password", this.loginForm.password); //存储
							localStorage.setItem('rememberTime', new Date().getTime());
					}
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            this.$router.push({ path: '/index' })
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    phoneLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/phoneLogin', this.loginForm).then(() => {
            this.$router.push({ path: '/index' })
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  margin-top: 20px;
  width: 800px;
  position: relative;
  left: 50%;
  margin-left: -400px;
  top: 163px;

  .text {
    display: block;
    text-align: center;
    height: 30px;
    color: white;
    line-height: 30px;
    font-size: 14px;
  }
}
</style>

<style scoped>
.el-form-item{
  margin-bottom: 0px;
}
</style>
<style>
html,body{
  width:100%;
  height:100%;
  margin: 0;
  padding: 0;
}
.layui-layer-msg{
  top: 90px!important;
}
.login-container{
  /* background: url(../../assets/images/login-bg.jpg) no-repeat; */
  background: #2D3A4B;
  background-size: 100% 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}
.login-container .system-title{
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: white;
  background: #385C80;
  font-size: 26px;
}
.console-header-wrapper{
  position: relative;
  z-index: 2;
}
.login-container .login{
  width: 400px;
  height: 400px;
  position: relative;
  left: 50%;
  margin-left: -200px;
  top: 163px;
  background: white;
  border-radius: 5px;
}
.login-container .login > .title{
  font-size: 36px;
  color:white;
  position: absolute;
  margin-left: 70px;
  top: -60px;
  text-shadow: #000 0px 1px 3px;
}
.login-container .login .e-tab{
  padding: 30px;
}
.login-container .login .e-tab .e-title{
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  letter-spacing:1px;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 25px;
}
.login-container .login .e-tab .e-title .item{
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}
.login-container .login .e-tab .e-title .item.selected{
  border-bottom: 2px solid #333333;
}
.login-container .login .e-tab .e-content input{
  background: #F4F4F4;
  border:1px solid #DDDDDD;
  border-radius: 5px;
  color:#999999;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px 25px;
}
.login-container .login .e-tab .e-content .item{
  display: none;
}
.login-container .login .e-tab .e-content .item.selected{
  display: block;
}
.login-container .login .e-tab .e-content .login-btn,
.login-container .login .e-tab .e-content .get-code{
  background: #385C80;
  font-size: 16px;
  color: white;
  width: 100%;
  display: block;
  padding: 10px 25px;
  border: 0px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.login-container .login .e-tab .e-content .get-code:disabled{
  background: #ccc;
}
.login-container .login .e-tab .e-content .get-code{
  display: inline;
  width: 50%;
  height: 40px;
  margin-left: 12px;
  vertical-align: top;
}
.login-container .login .e-tab .forgot-pwd-reg{
  text-align: right;
  margin-top: 20px;
  font-size: 16px;
}
.login-container .login .e-tab .forgot-pwd-reg a{
  color: #A0A0A0!important;
}
</style>
